<template>
    <b-overlay :show="isLoading">
        <b-card class="white-bg" v-if="repair">
            <b-row>
                <b-col>
                    <b-table-simple responsive bordered striped>
                        <b-tbody>
                            <b-tr>
                                <b-td style="width: 16%"><strong>GE Repair Job #:</strong></b-td>
                                <b-td style="width: 16%">{{ repair.repairsJobCal }}</b-td>
                                <b-td style="width: 16%"><strong>Parts Received at Repair:</strong></b-td>
                                <b-td style="width: 16%">{{ repair.receiptDate }}</b-td>
                                <b-td style="width: 16%"><strong>Next Unit:</strong></b-td>
                                <b-td style="width: 16%">*</b-td>
                            </b-tr>
                            <b-tr>
                                <b-td style="width: 16%"><strong>GE Part Number:</strong></b-td>
                                <b-td style="width: 16%">{{ repair.plcDrawingNo }}</b-td>
                                <b-td style="width: 16%"><strong>FPL PO:</strong></b-td>
                                <b-td style="width: 16%" colspan="3">{{ repair.soHdrHdrPoNumber }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-td style="width: 16%"><strong>Component:</strong></b-td>
                                <b-td style="width: 16%">{{ repair.partsDrawing }}</b-td>
                                <b-td style="width: 16%"><strong>CSP Number:</strong></b-td>
                                <b-td style="width: 16%" colspan="3">{{ repairData && repairData.cspNumber ? repairData.cspNumber : '' }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-td style="width: 16%"><strong>Repair Shop:</strong></b-td>
                                <b-td style="width: 16%">{{ repair.jobDetailsShopName ? repair.jobDetailsShopName : repair.shopName }}</b-td>
                                <b-td style="width: 16%"><strong>WO:</strong></b-td>
                                <b-td style="width: 16%" colspan="3">{{ repairData && repairData.newRepairWO ? repairData.newRepairWO : '' }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-td style="width: 16%"><strong>Parts Removed From:</strong></b-td>
                                <b-td style="width: 16%">{{ repairData && repairData.site ? repairData.site.name : '' }}</b-td>
                                <b-td style="width: 16%"><strong>Outgoing GE Part Number:</strong></b-td>
                                <b-td style="width: 16%" colspan="3">*</b-td>
                            </b-tr>
                            <b-tr>
                                <b-td style="width: 16%" colspan="6"><strong>Comments</strong></b-td>
                            </b-tr>
                            <b-tr>
                                <b-td style="width: 16%" colspan="6">{{ repair.comments }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                    <b-row>
                        <b-col lg="6">
                            <b-table-simple responsive bordered striped>
                                <b-thead>
                                    <b-tr>
                                        <b-th colspan="2">Request Details</b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <b-tr>
                                        <b-th style="width: 20%"><strong>Contract:</strong></b-th>
                                        <b-td>
                                            {{ repair.soHdrFinalCustPartyName }} / {{ repair.contractNumber }}
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th style="width: 20%"><strong>Site Name:</strong></b-th>
                                        <b-td>{{ repair.unitText }}</b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th style="width: 20%"><strong>Serial Number:</strong></b-th>
                                        <b-td>{{ repair.ibatEquipSerialNumber }}</b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th style="width: 20%"><strong>Equip Type:</strong></b-th>
                                        <b-td>{{ repair.equipType }}</b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </b-col>
                        <b-col lg="3">
                            <b-table-simple responsive bordered striped>
                                <b-thead>
                                    <b-tr>
                                        <b-th colspan="2">Task Details</b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <b-tr>
                                        <b-th style="width: 20%"><strong>Number:</strong></b-th>
                                        <b-td>{{ repair.soHdrHdrPoNumber }}</b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th style="width: 20%"><strong>Type:</strong></b-th>
                                        <b-td>{{ repair.poType }}</b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th style="width: 20%"><strong>Requestor:</strong></b-th>
                                        <b-td>{{ repair.endCustomerContact }}</b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </b-col>
                        <b-col lg="3">
                            <b-table-simple responsive bordered striped>
                                <b-thead>
                                    <b-tr>
                                        <b-th colspan="2">Event Details</b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <b-tr>
                                        <b-th style="width: 40%"><strong>Number:</strong></b-th>
                                        <b-td>{{ repair.evOfsEventId }}</b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th style="width: 20%"><strong>Fuel Type:</strong></b-th>
                                        <b-td>{{ repair.fuelType }}</b-td>
                                    </b-tr>
<!--                                    <b-tr>-->
<!--                                        <b-th style="width: 20%"><strong>Type:</strong></b-th>-->
<!--                                        <b-td>*</b-td>-->
<!--                                    </b-tr>-->
<!--                                    <b-tr>-->
<!--                                        <b-th style="width: 20%"><strong>Description:</strong></b-th>-->
<!--                                        <b-td>{{ repair.description }}</b-td>-->
<!--                                    </b-tr>-->
                                </b-tbody>
                            </b-table-simple>
                        </b-col>
                    </b-row>
                    <b-table-simple responsive bordered striped>
                        <b-tbody>
                            <b-tr>
                                <b-td><strong>FFH Since Last Repair:</strong></b-td>
                                <b-td style="width: 16%">{{ repair.ffhSinceLastRepair }}</b-td>
                                <b-td><strong>OP Start Since Last Repair:</strong></b-td>
                                <b-td style="width: 16%">{{ repair.opStartSinceLastRepair }}</b-td>
                                <b-td style="width: 16%"><strong>Total Fired Hours:</strong></b-td>
                                <b-td style="width: 16%">{{ repair.totalFiredHours }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-td><strong>FFS Since Last Repair:</strong></b-td>
                                <b-td>{{ repair.ffsSinceLastRepair }}</b-td>
                                <b-td><strong>OP Hrs Since Last Repair:</strong></b-td>
                                <b-td>{{ repair.opHrsSinceLastRepair }}</b-td>
                                <b-td><strong>Total Fired Starts:</strong></b-td>
                                <b-td>{{ repair.totalFiredStarts }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                    <b-table-simple responsive bordered striped>
                        <b-thead>
                            <b-tr>
                                <b-th colspan="6">Line Details</b-th>
                            </b-tr>
                            <b-tr>
                                <b-th>Line</b-th>
                                <b-th>Item</b-th>
                                <b-th>Item Description</b-th>
                                <b-th>UOM</b-th>
                                <b-th>QTY</b-th>
                                <b-th>Description</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr>
                                <b-td>1</b-td>
                                <b-td>*</b-td>
                                <b-td>*</b-td>
                                <b-td>*</b-td>
                                <b-td>1</b-td>
                                <b-td>*</b-td>
                            </b-tr>
                            <b-tr>
                                <b-td colspan="6">
                                    <b-table-simple responsive bordered striped class="mb-0">
                                        <b-thead>
                                            <b-tr>
                                                <b-th>Display Seq</b-th>
                                                <b-th>Set Serial # or Other Identifier or Part Serial</b-th>
                                                <b-th>System Set / Assy Identifier</b-th>
                                                <b-th>Part Description</b-th>
                                                <b-th>Complete Set or Extra Parts</b-th>
                                                <b-th>Quantity</b-th>
<!--                                                <b-th>Delete</b-th>-->
                                            </b-tr>
                                        </b-thead>
                                        <b-tbody>
                                            <b-tr>
                                                <b-th>1</b-th>
                                                <b-td>*</b-td>
                                                <b-td>*</b-td>
                                                <b-td>*</b-td>
                                                <b-td>*</b-td>
                                                <b-td>1</b-td>
<!--                                                <b-td class="text-center">
                                                    <b-button size="sm" v-b-tooltip.hover title="Delete" variant="outline-primary">
                                                        <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                                                    </b-button>
                                                </b-td>-->
                                            </b-tr>
                                        </b-tbody>
                                    </b-table-simple>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                    <b-table-simple responsive bordered striped>
                        <b-thead>
                            <b-tr>
                                <b-th colspan="4">Repair Information</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr>
                                <b-th style="width: 20%"><strong>Region:</strong></b-th>
                                <b-td>*</b-td>
                                <b-th style="width: 20%"><strong>Number Of Previous Repairs:</strong></b-th>
                                <b-td>*</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th style="width: 20%"><strong>CSM/CFS Sub Region:</strong></b-th>
                                <b-td>SOUTHEAST</b-td>
                                <b-th style="width: 20%"><strong>Component:</strong></b-th>
                                <b-td>{{ repair.partsDrawing }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th style="width: 20%"><strong>Repair Type:</strong></b-th>
                                <b-td>{{ repair.repairType }}</b-td>
                                <b-th style="width: 20%"><strong>Sub-Component:</strong></b-th>
                                <b-td>{{ repair.frameComponentSubcomponentScope }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th style="width: 20%"><strong>Frame Size:</strong></b-th>
                                <b-td colspan="3">{{ repair.plcFrameDescription }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                    <b-table-simple responsive bordered striped>
                        <b-thead>
                            <b-tr>
                                <b-th colspan="2">Additional Information</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr>
                                <b-th><strong>Comments:</strong></b-th>
                            </b-tr>
                            <b-tr>
                                <b-td>{{ repair.comments }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
            <template #footer>
                <div class="text-right">
                    <b-button variant="outline-secondary" :to="{ name: 'RepairIndex' }" class="mr-2"><font-awesome-icon icon="angle-left"></font-awesome-icon> Regresar</b-button>
<!--                    <b-button variant="secondary" :to="{ name: 'RepairIndex' }">
                        <font-awesome-icon icon="edit"></font-awesome-icon> Modificar
                    </b-button>-->
                </div>
            </template>
        </b-card>
    </b-overlay>
</template>

<script>

import { repairUrl } from '@routes';
import * as constants from '@constants';

export default {
    data() {
        return {
            breadcrumb: {
                title: 'Repairs',
                path: [
                    {
                        name: 'RepairIndex',
                        text: 'Repairs'
                    }
                ]
            },
            isLoading: false,
            repair: null,
            toastTitle: 'Repair',
            repairData: null
        };
    },
    created() {
        this.breadcrumb.path.push({
            name: 'RepairShow',
            text: 'Detail'
        });

        this.loadData();

        this.$emit('update-breadcrumb', this.breadcrumb);
    },
    methods: {
        loadData() {
            this.isLoading = true;

            this.axios.get(repairUrl(this.$route.params.id)).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    this.repair = response.data.repair;
                    this.repairData = response.data.repairData;
                }
            }).catch(error => {
                console.error(error);

                this.$emit('reset-item-form-id');

                this.$bvToast.toast('There was an error getting the repair data', {
                    title: this.toastTitle,
                    variant: 'danger'
                });
            }).finally(() => {
                this.isLoading = false;
            });
        }
    }
};
</script>